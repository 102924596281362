<template>
    <div>
        <vs-table search stripe border :multiple="this.selection" v-model="selected" description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                
                <div class="flex items-center justify-between p-2 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                    <span class="mr-2">Selection </span>
                    <vs-switch v-model="selection" class="mr-2" @input="(v)=>{this.selected=[]}">
                        <span slot="on">On</span>
                        <span slot="off">Off</span>
                    </vs-switch>
                    <vs-button v-if="selected.length > 0" size="small" class="mr-2 " @click="requestMultiApproval">Submit selected for Approval</vs-button>
                </div>
            </template>
            <template slot="thead">
                <vs-th>Action </vs-th>
                <vs-th sort-key="code">SR Type </vs-th>
                <vs-th sort-key="code">SR Data</vs-th>
                <vs-th sort-key="code">PO Data</vs-th>
                <vs-th sort-key="code">Supplier Data</vs-th>
                <vs-th sort-key="">Warehouse</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button
                                v-if="status === 'Draft' && this.$store.getters['user/hasPermissions']('edit')"
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-edit"
                                title="Edit"
                                style="margin-right: 5px"
                                @click="handleEdit(tr.id, tr.purchase_order_id)"
                            />
                            <vs-button
                                v-if="this.$store.getters['user/hasPermissions']('view')"
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-eye"
                                title="Detail"
                                style="margin-right: 5px"
                                @click="handleDetail(tr.id, tr.purchase_order_id)"
                            />
                            <vs-button
                                v-if="this.$store.getters['user/hasPermissions']('view')"
                                size="small"
                                color="#3D9"
                                icon-pack="feather"
                                icon="icon-printer"
                                title="Print"
                                style="margin-right: 5px"
                                @click="handlePrint(tr.id,tr.purchase_order_id, true)"
                            />
                            <vs-button
                                v-if="status == 'Draft' && this.$store.getters['user/hasPermissions']('cancel')"
                                size="small"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-x-square"
                                title="Cancel"
                                style="margin-right: 5px"
                                @click="handleCancel(tr.id, tr.purchase_order_id)"
                            />
                            <vs-button
                                v-if="status == 'Draft' && this.$store.getters['user/hasPermissions']('create')"
                                size="small"
                                color="success"
                                icon-pack="feather"
                                icon="icon-check"
                                title="Request Approval"
                                @click="handleApprovalRequest(tr.id, tr.purchase_order_id)"
                            />
                            <vs-button
                                v-if="status == 'Approved' && this.$store.getters['user/hasPermissions']('create')"
                                size="small"
                                color="success"
                                icon-pack="feather"
                                icon="icon-check"
                                title="Release"
                                @click="handleRelease(tr.id, tr.purchase_order_id)"
                            />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            {{Number(tr.purchase_order_id)>0 ? 'With Reference':'Without Reference'}}
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.code }}</div>
                            <div>SR External Code : {{ tr.sr_ref }}</div>
                            <div>GRR External Code : {{ tr.ref_code_external ? tr.ref_code_external : '' }}</div>
                            <div>Created At : {{ formatDate(tr.created_at) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <!-- {{ tr.po_code }} -->
                        <div v-if="tr.purchase_order_id">
                            <div>Code : {{ tr.po_code }}</div>
                            <div>Created At : {{ formatDate(tr.po_created_at) }}</div>
                        </div>
                        <div v-else>-</div>
                    </vs-td>
                    <vs-td key="supplier">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.supplier_code }}</div>
                            <div>Name : {{ tr.supplier_name }}</div>
                            <div>City : {{ tr.supplier_city }}</div>
                        </div>
                    </vs-td>
                    <vs-td key="warehouse">
                        <div v-if="tr.warehouse.id > 0" class="flex flex-col content-center">
                            <div>Code : {{ tr.warehouse.code }}</div>
                            <div>Name : {{ tr.warehouse.name }}</div>
                            <div>City : {{ tr.warehouse.city }}</div>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        RefType: {
            type: String,
            default: "",
        },
        supplierId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Draft",
        },
        date: {
            type: Object,
        },
    },
    data() {
        return {
            selection:false,
            deleteId: null,
            table: this.tableDefaultState(),
            selected: [],
            timeOuts: null,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "created_at",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();
            this.$http.get(this.$store.state.purchase.poReturn.baseUrlPath, {
                params: {
                    limit: this.table.length,
                    page: this.table.page,
                    query: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    warehouse_id: this.sourceWarehouseId,
                    supplier_id: this.supplierId,
                    type: this.RefType,
                    status: this.status,
                    transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
                    create_start: (this.date.startDate) ? moment(this.date.startDate).format("YYYY-MM-DD") : null,
                    create_end: (this.date.endDate) ? moment(this.date.endDate).format("YYYY-MM-DD") : null,
                },
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_rows;
                    this.table.totalPage = resp.data.total_pages;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.limit;
                    this.table.data = resp.data.rows?resp.data.rows:[];
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error)
            })
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleDelete(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Please confirm to delete this data",
                accept: this.acceptDelete,
            });
        },
        handleApprovalRequest(id, po) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Are you sure to send request approval for this data?",
                accept: () => {
                    const basePath = po > 0 ? this.$store.state.purchase.poReturn.baseUrlPath : this.$store.state.purchase.supplierReturn.baseUrlPath
                    this.$vs.loading();
                    this.$http.post(`${basePath}/${id}/status/waiting`)
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.dialog({
                                color: "success",
                                title: "Success request to approval",
                                text: resp.message,
                            });
                            this.getData();
                        } else {
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        console.log(error)
                        this.$vs.dialog({
                            color: "danger",
                            title: "Error",
                            text: error,
                        });
                    });
                },
            });
        },
        handleMencoba(id, po) {
            this.$http.post("api/wms/v1/wms/purchase/po-return/segar-menyegarkan")
            .then((resp) => {
                if (resp.code == 200) {
                    console.log(resp.data)
                } else {
                    console.log(resp.data)
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleRelease(id, po) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Are you sure to release this data?",
                accept: () => {
                    const basePath = po > 0 ? this.$store.state.purchase.poReturn.baseUrlPath : this.$store.state.purchase.supplierReturn.baseUrlPath
                    this.$vs.loading();
                    this.$http.post(`${basePath}/${id}/status/released`)
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.dialog({
                                color: "success",
                                title: "The data was successfully released, check released tab",
                                text: resp.message,
                            });
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: "The data was successfully released, check released tab.",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.getData();
                        } else {
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        console.log(error)
                        this.$vs.dialog({
                            color: "danger",
                            title: "Error",
                            text: error,
                        });
                    });
                },
            });
        },
        handleEdit(id, po) {
            this.$router.push({
                name: this.$store.state.purchase.poReturn.baseRouterName + ".draft-edit",
                params: { id: id, po: po },
            });
        },
        handleDetail(id, po) {
            this.$router.push({
                name: this.$store.state.purchase.poReturn.baseRouterName + ".detail",
                params: { id: id, po: po },
            });
        },
        handlePrint(id,po, print) {
            this.$router.push({
                name: this.$store.state.purchase.poReturn.baseRouterName + ".print",
                params: { id: id, po:po },
                query: {print:print},
            });
        },
        requestMultiApproval(){
            var sr = [];
            var pr = [];
            this.selected.forEach((v)=>{
                v.purchase_order_id > 0 ?pr.push(v.id):sr.push(v.id);
            });

            // this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to request approval for selected purchase return?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post(`${this.$store.state.purchase.poReturn.baseUrlPath}/multi-approve-req`,{
                        "sr":sr,
                        "pr":pr,
                    })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }

                        this.$vs.loading.close();
                        this.getData();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                },
            });
        },
        handleCancel(id, po) {
            // this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to cancel this data?",
                accept: () => {
                    this.$vs.loading();
                    const baseUrl = po > 0 ? this.$store.state.purchase.poReturn.baseUrlPath : this.$store.state.purchase.supplierReturn.baseUrlPath
                    this.$http.post(`${baseUrl}/${id}/status/canceled`)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "success",
                                title: "Success canceled this data",
                                text: resp.message,
                            });
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            });
                        }

                        this.getData();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.dialog({
                            color: "danger",
                            title: "Error",
                            text: error,
                        });
                    });
                },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    mounted() {
        this.$store.dispatch("user/getPermissions", "supplier-return");
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
};
</script>
  